import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getCustomerInfo, updateCustomer } from '../../actions/customerActions';
import Fade from '@material-ui/core/Fade';

import Checkbox from '@material-ui/core/Checkbox';

const CustomerInfo = ({ match: { params } }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const listInputs = useRef(null);

    const handleCustomer = useCallback(async (params) => {
        setLoading(true)
        const response = await getCustomerInfo(params);


        await setData(response);
        await setLoading(false);
    }, []);

    useEffect(() => {
        handleCustomer(params)

        return () => setLoading(false);
    }, []);

    const checkName = (name) => {
        if((name === 'username' || name === 'active' || name === 'membership' || name === 'id' || name === 'guid' || name === 'created_at' || name === 'updated_at')) {
            return name === 'username' ? 'email' : name
        }
    }

    const checkProfileName = (name) => {
        if (name !== 'alias' && name !== 'email' && name !== 'active' && name !== 'membership' && name !== 'id' && name !== 'guid' && name !== 'created_at' && name !== 'updated_at') {
            return name
        }
        return null; // Явно повертаємо `null`, якщо ім'я у винятках
    };
    

    const handleSubmit = useCallback(async () => {
        if (listInputs?.current) {
            setLoading(true)
            let data = {}
            listInputs.current.querySelectorAll('input, select').forEach((item) => {
                console.log(data)
                data = {
                    ...data,
                    ...((item.name && checkName(item.name)) && {[checkName(item.name)] : item.type === 'checkbox' ? item.checked : item.value}),
                    profile : {
                        ...data.profile,
                        ...((item.name && checkProfileName(item.name)) && {[checkProfileName(item.name)] : item.type === 'checkbox' ? item.checked : item.value}),
                    },
                    ...(item.name === 'alias' && {
                        bank_account: {
                            username: item.value
                        }
                    })
                  
                }
            });
            await updateCustomer(params.id, data, params['bank_id'] )

            setLoading(false)
        }
        
    }, [listInputs]);

    return (
        <div className='row'>
            <div className='col-12 col-lg-12'>
                <div className='card'>
                    <div className="card-header">
                        <i className="fa fa-align-justify"></i> Customer details
                    </div>

                    <div className='card-body customerrr'>
                        <div className='row'>
                            <div className="col-12 col-lg-6">
                                <fieldset className="p-2 pb-5 pl-5 pr-5">
                                    <legend className="d-inline">User Details</legend>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0px, 1fr))', gap: '70px' }}>
                                            <div className="list-p" style={{ fontSize: 14 }}>
                                                <p>Active</p>
                                                {/* <p>Business Client</p> */}
                                                <p>ID</p>
                                                <p>GUID</p>
                                                <p>Username</p>
                                                <p>First Name</p>
                                                <p>Last Name</p>
                                                <p>Alias</p>
                                                <p>Member</p>
                                                <p>Create at</p>
                                                <p>Update at</p>
                                                <p>Gender</p>
                                                <p>Birthday</p>
                                            </div>

                                            {Object.values(data).length > 0 ?
                                                <div ref={listInputs} className='listValue'>
                                                    {/* <input className="form-control" type="text" defaultValue={JSON.stringify(data?.active) || '-'}></input> */}
                                                    <Checkbox
                                                        name={'active'}
                                                        defaultChecked={data?.active}
                                                    />
                                                    {/* <input className="form-control" readOnly type="text" defaultValue={'-'}></input> */}
                                                    <input className="form-control" readOnly name="id" type='number' defaultValue={data?.id}></input>
                                                    <input className="form-control" name="guid" readOnly type='number' defaultValue={data?.guid}></input>
                                                    <input className="form-control" name="username" type='text' defaultValue={(data?.email)}></input>
                                                    <input className="form-control" name="first_name" type='text' defaultValue={(data?.profile?.first_name)}></input>
                                                    <input className="form-control" name="last_name" type='text' defaultValue={(data?.profile?.last_name)}></input>

                                                    <input className="form-control" name="alias" type="text" defaultValue={data?.bank_account?.username}></input>
                                                    <Checkbox
                                                        name={'membership'}
                                                        defaultChecked={data?.membership}
                                                    />
                                                    <input className="form-control" readOnly name="created_at" type="date" defaultValue={data?.created_at?.split('T')[0]}></input>
                                                    <input className="form-control" readOnly name="updated_at" type="date" defaultValue={data?.updated_at?.split('T')[0]}></input>

                                                    <select className="form-control" name="gender" defaultValue={data?.profile?.gender}>
                                                        <option value="" selected disabled>Choose Gender</option>
                                                        <option value={'male'}>male</option>
                                                        <option value={'female'}>female</option>
                                                    </select>


                                                    {/* <input className="form-control" type="text" name="gender" defaultValue={data?.profile?.gender}></input> */}


                                                    <input className="form-control" type="date" name="birthday" defaultValue={data?.profile?.birthday?.split('T')[0]}></input>

                                                </div> : null
                                            }

                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mt-5" onClick={handleSubmit} style={{ maxWidth: 'fit-content' }}>Submit</button>
                    </div>



                    <Fade in={loading} mountOnEnter unmountOnExit>
                        <span style={{
                            position: 'fixed',
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            marginLeft: 150,
                            color: 'black',
                            background: 'white',
                            zIndex: 100,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 40,
                            textAlign: 'center'
                        }}>Loading ...</span>
                    </Fade>
                </div>
            </div>
        </div>

    )
}

export default CustomerInfo;